import { GettingStartedWith } from '@itilite/iui-getting-started'
import '@itilite/iui-getting-started/dist/index.css'
import './GettingStartedWith.scss'

import SkeletonLoaderOnboarding from "../Onboarding/ProfileDetails/SkeleteonLoaderOnboarding";

function GettingStarted({ result, isLoading }) {
    const productSupportLink = process.env.REACT_APP_PROD_SUPPORT_URL;
    return (
        Object.keys(result).length && !isLoading
            ? <GettingStartedWith result={result} productSupportLink={productSupportLink} />
            : (
                <div className='getting-started-loader-container'>
                    <SkeletonLoaderOnboarding />
                </div>
            )
    )
}

export default GettingStarted
