import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Navbar.scss";
// import navbar from "./Navbar.json";  // static navbar json
import { v4 as uuidv4 } from "uuid";
import * as headerAction from "../../redux/actions/common/Header.action";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    List,
    ListItem,
    Link,
    Container,
    AppBar,
    Toolbar,
    Box,
    ClickAwayListener,
    Button,
    IconButton,
    SwipeableDrawer,
    Typography,
    Switch,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import __globals from "../../theming/__globals";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserIcon from "../UserIcon";
import LocalStorageService from "../../services/LocalStorage";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { spacer4, themeMain } from "../../theming/newVariables";
import { userLogout } from "../../utils/_helperFunctions";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    height100: {
        height: "100%",
    },
    padding0: {
        padding: 0,
    },
    itilite__navbar: {
        boxShadow: `0px 0px 16px -7px ${__globals.palette.secondary.light}`,
    },
    navbar: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: 0,
    },
    menuButton: {
        [theme.breakpoints.down("md")]: {
            display: "block",
        },
        display: "none",
    },
    navItem: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        whiteSpace: "nowrap",
        padding: 7,
    },
    navItemReponsive: {
        minHeight: 56,
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: "-1px",
        backgroundColor: "rgba(0, 0, 0, 0.0)",
    },
    navLink: {
        color: __globals.palette.secondary.dark,
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: 400,
        display: "flex",
        width: "100%",
    },
    navDropdown: {
        position: "absolute",
        background: "#fff",
        padding: 10,
        top: "100%",
        width: "200px",
    },
    hideDropdown: {
        display: "none",
    },
    showDropdown: {
        display: "block",
    },
    title: {
        flexGrow: 1,
    },
    rotate180: {
        transform: "rotate(180deg)",
    },
    dropdown: {
        top: `calc(100% + 3px)`,
        background: __globals.palette.common.white,
        maxHeight: 500,
        overflowY: "auto",
        width: "auto",
        minWidth: "170px",
        boxShadow:
            "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
    },
    dropdownItem: {
        padding: "15px",
    },
    alignedRight: {
        right: 0,
    },
    alignedLeft: {
        left: 0,
    },
    selectButton: {
        background: "#fff",
        borderRadius: 5,
        border: `0`,
        color: __globals.palette.secondary.dark,
        textAlign: "left",
        display: "flex",
        boxShadow: "none",
        "&:hover": {
            background: "#fff",
        },
        padding: 7,
        whiteSpace: "nowrap",
    },
    toggle: {
        padding: spacer4,
    },
    toggleWrapper: {
        padding: `0 ${spacer4}`,
    },
    props: {
        MuiButtonBase: {
            disableRipple: true, // No more ripple, on the whole application
        },
        MuiLink: {
            root: {
                fontSize: 14,
            },
        },
        MuiContainer: {
            root: {
                height: "100%",
            },
        },
    },
}));

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        padding: 0,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        display: "block",
    },
}))(MuiAccordionDetails);

const CustomSwitch = withStyles((theme) => ({
    root: {
        width: 38,
        height: 22,
        padding: 0,
        margin: 0,
        overflow: "unset",
    },
    switchBase: {
        padding: 4,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: themeMain,
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: themeMain,
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 14,
        height: 14,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[200],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

function Header() {
    const classes = useStyles();
    const { t } = useTranslation();

    // accessing the state from reducer
    const { headerLinks } = useSelector((state) => state.HeaderReducer);
    const { authDetails } = useSelector((state) => state.AuthReducer);
    const [expanded, setExpanded] = React.useState("");
    const [drawer, setDrawer] = React.useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (authDetails.role) {
                await dispatch(
                    headerAction.fetchHeaderLinks(
                        "accessToken",
                        "GET",
                        "data",
                        authDetails.role
                    )
                );
            }
        })();
    }, [authDetails.role]);
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawer(open);
    };

    const renderNavLinks = (navObj, i, l, type = "normal") => {
        if (navObj.has_children === 0) {
            return (
                <ListItem
                    key={uuidv4()}
                    className={clsx(
                        classes.navItem,
                        type === "responsive"
                            ? classes.navItemReponsive + " px-3"
                            : ""
                    )}
                    alignItems="center"
                >
                    <Link
                        href={`${navObj.base_url}/${navObj.link_path}`}
                        underline="none"
                        color="primary"
                        className={clsx(
                            classes.navLink,
                            type === "responsive"
                                ? classes.height100 +
                                      " text--semibold text--md text-color__medium d-flex"
                                : ""
                        )}
                        target={navObj.same_site === "1" ? "_self" : "_blank"}
                    >
                        {t(`${navObj.display_name}`)}
                    </Link>
                </ListItem>
            );
        } else if (navObj.has_children === 1) {
            return type === "normal" ? (
                <NavbarDropdown
                    aligned={i < l - 1 ? "left" : "right"}
                    list={navObj}
                    key={uuidv4()}
                />
            ) : (
                <NavbarAccordian list={navObj} key={uuidv4()} />
            );
        }
    };

    const NavbarAccordian = ({ list }) => {
        const handleChange = (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };
        const [toggle, setToggle] = React.useState(true);

        const changeToggleStatus = (e, child) => {
            const _index = e.target.checked ? 1 : 0;
            setToggle(e.target.checked);
            setTimeout(() => {
                window.location.href =
                    child[_index].base_url + "/" + child[_index].link_path;
            }, 1000);
        };
        const uniqueId = "panel" + list.id;

        return (
            <Accordion
                square
                expanded={expanded === uniqueId}
                onChange={handleChange(uniqueId)}
            >
                <AccordionSummary
                    aria-controls={`${uniqueId}-content`}
                    id={`${uniqueId}-header`}
                    expandIcon={<ExpandMoreIcon />}
                    className={clsx("px-3")}
                >
                    <Typography
                        className={clsx(
                            "d-flex",
                            "align-items-center",
                            "text--semibold",
                            "text--md",
                            "text-color__medium"
                        )}
                    >
                        {list.name === "profile-items"
                            ? authDetails.first_name
                            : list.display_name}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={clsx("p-0")}>
                    {list && list.type === "toggle" && (
                        <Box
                            component="div"
                            className={classes.toggle}
                            display="flex"
                        >
                            <Typography
                                variant="caption"
                                className={`${classes.activeMargin} text-color__black  text--md`}
                            >
                                {list.child[0].display_name}
                            </Typography>
                            <Box
                                component="div"
                                className={classes.toggleWrapper}
                            >
                                <CustomSwitch
                                    checked={toggle}
                                    disabled={false}
                                    size={"medium"}
                                    onChange={(e) => {
                                        changeToggleStatus(e, list.child);
                                    }}
                                />
                            </Box>
                            <Typography
                                variant="caption"
                                className={`${classes.activeMargin} text-color__black  text--md`}
                            >
                                {list.child[1].display_name}
                            </Typography>
                        </Box>
                    )}
                    {list &&
                        list.type === "drop-down" &&
                        list.child?.map((dropdownItem) => (
                            <ListItem
                                key={uuidv4()}
                                divider={true}
                                className={clsx(
                                    classes.dropdownItemResponsive,
                                    "d-block"
                                )}
                                keys={uuidv4()}
                            >
                                <Link
                                    key={uuidv4()}
                                    href={`${dropdownItem.base_url}/${dropdownItem.link_path}`}
                                    underline="none"
                                    color="primary"
                                    onClick={(e) => {
                                        if (
                                            dropdownItem.link_path === "logout"
                                        ) {
                                            LocalStorageService.removeAccessToken();
                                            localStorage.removeItem("token");
                                        }
                                    }}
                                    className={clsx(
                                        classes.navLink,
                                        "py-3 pl-3",
                                        "text--semibold",
                                        "text--md",
                                        "text-color__medium"
                                    )}
                                    target={
                                        dropdownItem.same_site === "1"
                                            ? "_self"
                                            : "_blank"
                                    }
                                >
                                    {t(`${dropdownItem.display_name}`)}
                                </Link>
                            </ListItem>
                        ))}
                </AccordionDetails>
            </Accordion>
        );
    };

    const NavbarDropdown = ({ list, aligned = null, margin = "0 0" }) => {
        const [openDropdown, setOpenDropdown] = React.useState(false);
        const [toggle, setToggle] = React.useState(true);

        const handleClickAway = () => {
            setOpenDropdown(false);
        };
        const handleClick = () => {
            setOpenDropdown(!openDropdown);
        };

        const changeToggleStatus = (e, child) => {
            const _index = e.target.checked ? 1 : 0;
            setToggle(e.target.checked);
            localStorage.clear();
            setTimeout(() => {
                window.location.href =
                    child[_index].base_url + "/" + child[_index].link_path;
            }, 1000);
        };

        return (
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box position="relative">
                    <Button
                        classes={{
                            root: `${classes.navLink} ${classes.selectButton}`,
                        }}
                        onClick={handleClick}
                        style={{ margin: margin }}
                        startIcon={
                            list.name === "profile-items" ? (
                                <UserIcon className="mr-0" />
                            ) : null
                        }
                    >
                        {list.name === "profile-items"
                            ? authDetails.first_name
                            : list.display_name}
                        <ExpandMoreIcon
                            classes={{
                                root: openDropdown && classes.rotate180,
                            }}
                        />
                    </Button>
                    {openDropdown && (
                        <Box
                            className={`${classes.dropdown} ${
                                aligned === null || aligned === "left"
                                    ? classes.alignedLeft
                                    : classes.alignedRight
                            }`}
                            position="absolute"
                            zIndex={1}
                            borderRadius={7}
                        >
                            {list && list.type === "toggle" && (
                                <Box
                                    component="div"
                                    className={classes.toggle}
                                    display="flex"
                                >
                                    <Typography
                                        variant="caption"
                                        className={`${classes.activeMargin} text-color__black  text--md`}
                                    >
                                        {list.child[0].display_name}
                                    </Typography>
                                    <Box
                                        component="div"
                                        className={classes.toggleWrapper}
                                    >
                                        <CustomSwitch
                                            checked={toggle}
                                            disabled={false}
                                            size={"medium"}
                                            onChange={(e) => {
                                                changeToggleStatus(
                                                    e,
                                                    list.child
                                                );
                                            }}
                                        />
                                    </Box>
                                    <Typography
                                        variant="caption"
                                        className={`${classes.activeMargin} text-color__black  text--md`}
                                    >
                                        {list.child[1].display_name}
                                    </Typography>
                                </Box>
                            )}
                            {list &&
                                list.type === "drop-down" &&
                                list.child?.map((dropdownItem) => (
                                    <ListItem
                                        key={uuidv4()}
                                        divider={true}
                                        className={classes.dropdownItem}
                                        keys={uuidv4()}
                                    >
                                        {dropdownItem.link_path === "logout" ? (
                                            <Link
                                                key={uuidv4()}
                                                underline="none"
                                                color="primary"
                                                onClick={() =>
                                                    userLogout(
                                                        `${dropdownItem.base_url}/${dropdownItem.link_path}`
                                                    )
                                                }
                                                classes={{
                                                    root: classes.navLink,
                                                }}
                                            >
                                                {t(
                                                    `${dropdownItem.display_name}`
                                                )}
                                            </Link>
                                        ) : (
                                            <Link
                                                key={uuidv4()}
                                                href={`${dropdownItem.base_url}/${dropdownItem.link_path}`}
                                                underline="none"
                                                color="primary"
                                                classes={{
                                                    root: classes.navLink,
                                                }}
                                                target={
                                                    dropdownItem.same_site ===
                                                    "1"
                                                        ? "_self"
                                                        : "_blank"
                                                }
                                            >
                                                {t(
                                                    `${dropdownItem.display_name}`
                                                )}
                                            </Link>
                                        )}
                                    </ListItem>
                                ))}
                        </Box>
                    )}
                </Box>
            </ClickAwayListener>
        );
    };

    return (
        <>
            <AppBar position="fixed" style={{ height: "64px" }}>
                <Toolbar
                    className={clsx(
                        classes.itilite__navbar,
                        classes.height100,
                        "p-0"
                    )}
                >
                    <Container maxWidth="xl" className={classes.height100}>
                        <Box
                            display="flex"
                            width="100%"
                            className={classes.height100}
                        >
                            <Box
                                flexGrow={1}
                                display="flex"
                                alignItems="center"
                            >
                                {headerLinks.left &&
                                    headerLinks.left.length > 0 && (
                                        <Link
                                            href={
                                                headerLinks?.left[0]?.base_url
                                            }
                                        >
                                            <img
                                                src={`${process.env.REACT_APP_IL_CDN_URL}/images/Logo.svg`}
                                                alt="Itilite Logo"
                                            />
                                        </Link>
                                    )}
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <List
                                    className={clsx(
                                        classes.navbar,
                                        classes.height100
                                    )}
                                >
                                    {headerLinks &&
                                        headerLinks.right?.map(
                                            (navObj, index) => {
                                                return renderNavLinks(
                                                    navObj,
                                                    index,
                                                    headerLinks.right.length
                                                );
                                            }
                                        )}
                                </List>
                                <IconButton
                                    edge="start"
                                    className={clsx(classes.menuButton)}
                                    color={"primary"}
                                    aria-label="menu"
                                    onClick={toggleDrawer(true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                anchor={"top"}
                open={drawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Box
                    flexGrow={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className="pl-3"
                    style={{
                        minHeight: 60,
                        borderBottom: "1px solid rgba(0, 0, 0, .125)",
                    }}
                >
                    {headerLinks.left && headerLinks.left.length > 0 && (
                        <Link href={headerLinks.left[0].base_url}>
                            <img
                                src={`${process.env.REACT_APP_IL_CDN_URL}/images/Logo.svg`}
                                // src={headerLinks.left[0].path}
                                alt={headerLinks.left[0].name}
                            />
                        </Link>
                    )}
                    <IconButton
                        edge="start"
                        className={clsx(classes.height100)}
                        color={"primary"}
                        aria-label="menu"
                        onClick={toggleDrawer(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <List
                    className={`${classes.navbarMobile} ${classes.height100} p-0`}
                >
                    {headerLinks &&
                        headerLinks.right?.map((navObj, index) => {
                            return renderNavLinks(
                                navObj,
                                index,
                                headerLinks.right.length,
                                "responsive"
                            );
                        })}
                </List>
            </SwipeableDrawer>
        </>
    );
}

export default Header;
