export default function logErrors(error) {
    const formattedErrorString = Object.keys(error)
        .map((key) => `${key}: ${JSON.stringify(error[key])}`)
        .join("\n\n");
    const url = `${process.env.REACT_APP_GOOGLE_BOT_AUTH_URL}?key=${process.env.REACT_APP_GOOGLE_BOT_AUTH_KEY}&token=${process.env.REACT_APP_GOOGLE_BOT_AUTH_TOKEN}`;
    const data = {
        text: formattedErrorString,
    };
    // eslint-disable-next-line no-console
    console.log(error);

    if (process.env.REACT_APP_BOT_NOTIFICATION_ENABLED === "1")
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (!response.ok) {
                    // eslint-disable-next-line no-console
                    console.error("Bot Notification Request failed");
                }
                return response.json();
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error("Error:", error);
            });
}
